import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react';
import { ITaxonomyTerms } from '@kontent-ai/delivery-sdk';
import { Link } from 'components/base/Link';
import { Tag } from 'components/base/Tag';
import { htmlResolver } from 'helpers/htmlResolver';
import { LinkType } from 'lib/enums/LinkType';
import { ContentPage } from 'models';
import Image from 'next/image';
import { normalize } from 'pages/api/sitemap';
import { withTranslations } from 'store/translations';

import { getLocalizedDate } from './getLocalizedDate';
import { imageFormatter } from './imageFormatter';
export interface OptionsProps extends Partial<ITaxonomyTerms> {
  amountOfPagesWithTag?: number;
  amountOfOffersWithTag?: number;
  apiCodename?: string;
}
import { useCookies } from 'react-cookie';

export const ItemBlock = ({ page }: { page: ContentPage }) => {
  const translations = withTranslations();
  const imageData = imageFormatter(page.elements.summaryImageFrontify.value);
  const title = page.elements.summaryTitle.value;
  const description = htmlResolver(page.elements.summaryDescription);
  const typeArray = page.elements[
    page.elements?.category.value[0]?.codename === 'insight' ? 'tagsThemeTopic' : 'tagsSector'
  ].value?.map(obj =>translations.sector(obj.codename));

  // const concatenatedNames = page.elements[
  //   page.elements?.category.value[0]?.codename === 'insight' ? 'tagsThemeTopic' : 'tagsSector'
  // ].value?.reduce((result, tag) => {
  //   return result + (result ? ', ' : '') +  translations.sector(tag.codename);
  // }, '');
  const type = typeArray.join(", ");
  // ?.codename;
  const featured = page.elements.featured.value[0]?.codename;
  const pageCreation = getLocalizedDate(
    page.elements.summaryDate.value ?? page.system.lastModified,
    translations.locale,
  );
  const contentType = page.elements.tagsContentType.value[0]?.codename;
  const pageType = page.elements.category.value[0]?.codename;
  const url = Object.keys(normalize(page, translations.locale, undefined, true, translations))[0];
  const [_cookie, setCookie] = useCookies();

  return (
    <Box
      w={{ base: '100%', lg: pageType === 'news' ? '33%' : '50%' }}
      pl={{ base: '0', lg: 's' }}
      pr={{ base: '0', lg: 's' }}
      pb='m'
    >
      <Box position={'relative'}>
        <AspectRatio w='100%' ratio={16 / 9}>
          <Image
            width={imageData.width}
            height={imageData.height}
            src={imageData.src}
            alt={imageData.alt}
            sizes={'(max-width: 1024px) 100vw, 50vw'}
          />
        </AspectRatio>
        <Flex gap='xxs' position='absolute' bottom='xs' right='xs'>
          {contentType !== undefined && <Tag type={contentType} aria-label='tag' />}
          {featured === 'yes' && <Tag type='featured' aria-label='tag' />}
        </Flex>
      </Box>
      <Text textStyle='mobile.body.s' pt='xs' aria-label='type'>
        {pageType === 'insight' ? (
          <>
            {translations.theme_topic(type)} | {pageCreation}
          </>
        ) : pageType === 'news' ? (
          <>{pageCreation}</>
        ) : (
          type
         // translations.sector(type)
        )}
      </Text>
      <Text textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }} pt='xxs' dangerouslySetInnerHTML={{ __html: title }} />
      <>
        {description?.map((desc, index) => {
          return (
            <Text
              key={index}
              textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }}
              pt='xs'
              pb='xs'
              dangerouslySetInnerHTML={{ __html: desc.body }}
            />
          );
        })}
      </>
      <Link
        href={url ?? '/'}
        locale={translations.locale}
        colorScheme='cyan.web'
        type={LinkType.SecondaryButton}
        iconName='chevronRightForLink'
        onClick={() => {
          switch (pageType) {
            case 'insight':
              setCookie('breadcrumb_name', translations.theme_topic(type));
              setCookie('breadcrumb_path', url);
              break;
            case 'news':
              setCookie('breadcrumb_name', pageCreation);
              setCookie('breadcrumb_path', url);
              break;
            default:
              // console.log('on click', translations.sector(type), pageType);
              setCookie('breadcrumb_name', translations.sector(type));
              setCookie('breadcrumb_path', url);
          }
          // if(pageType === "insight"){
          //   setCookie('breadcrumb_name', translations.theme_topic(type))
          //   setCookie('breadcrumb_path', url)
          // }else if(pageType === 'news'){
          //   setCookie('breadcrumb_name', pageCreation)
          //   setCookie('breadcrumb_path', url)
          // }else{

          //   setCookie('breadcrumb_name', translations.sector(type))
          //   setCookie('breadcrumb_path', url)
          // }
        }}
      >
        {/* TODO: This needs to come from Kontent.ai & have HREF */}
        {pageType === 'insight' || pageType === 'news'
          ? translations.common('readMore')
          : translations.common('viewPageType', { pageType: translations.category(pageType) })}
      </Link>
    </Box>
  );
};
// -- Product Block End --
